import router from '../router';

export default class NavigationHelper {
  /**
   * Navega para uma rota especificada pelo nome.
   *
   * @param {string} name - O nome da rota para a qual navegar.
   */
  public static navigate(name: string, params?: any) {
    router.push({ name, params });
  }

  /**
     * Navega para um caminho de rota completo especificado.
     *
     * @param {string} fullPath - O caminho completo da rota para a qual navegar.
     */
  public static navigateToPath(fullPath: string) {
    router.push(fullPath);
  }


  /**
   * Navega de volta para a página anterior no histórico de navegação.
   */
  public static goBack() {
    router.back();
  }
}
